import { ids } from "@seekdharma/common-types";
import { A, O, pipe } from "@seekdharma/std";
import {
  InventoryAndPaymentCoordinatorProfile,
  isEmployee,
  isRole,
  isTalentProfile,
  type BrandManagerProfile,
  type FinanceProfile,
  type Profile,
  type PurchaseManagerProfile,
  type TalentProfile,
} from "@seekdharma/studio-shared";
import type { History } from "history";
import { derived, get, type Readable, type Writable } from "svelte/store";
import { getAppContext } from "./AppContext";
import type { Auth } from "./Auth";
import type { Me } from "./auth-portal/Me";
import {
  clearAuthenticatedContext,
  setAuthenticatedContext,
} from "./AuthenticatedContext";
import type { BrandManagerPortal } from "./employee/brand-manager-portal/BrandManagerPortal";
import type { FinancePortal } from "./employee/finance-portal/FinancePortal";
import type { InventoryAndPaymentCoordinatorPortal } from "./employee/inventory-and-payment-coordinator-portal/InventoryAndPaymentCoordinatorPortal";
import type { ProductOwnerPortal } from "./employee/product-owner-portal/ProductOwnerPortal";
import type { PurchaseManagerPortal } from "./employee/purchase-manager-portal/PurchaseManagerPortal";
import type { TripPlannerPortal } from "./employee/trip-planner-portal/TripPlannerPortal";
import { Router } from "./lib/router";
import { routerToSvelteRouter } from "./lib/router-svelte";
import { QuickAccessPortal } from "./quick-access-portal/QuickAccessPortal";
import type { TalentPortal } from "./talent-portal/TalentPortal";

export type AuthenticatedApp = {
  active: Readable<O.Option<ActivePortal>>;
  me: Readable<Me>;
  init: () => void;
  destroy: () => void;
};

type Deps = {
  me: Writable<Me>;
  auth: Pick<Auth, "renew" | "logout">;
};

export const AuthenticatedApp = ({
  me,
  auth: { logout, renew: renewAuth },
}: Deps): AuthenticatedApp => {
  const { history, hotjar } = getAppContext();
  const selfIsEmployee = isEmployee(get(me).profiles);
  const selectProfile = SelectProfile(history, me, selfIsEmployee);
  setAuthenticatedContext({ logout, renewAuth, me, selectProfile });

  if (!selfIsEmployee)
    hotjar.identify(get(me).email, { role: get(me).profiles[0]!.role });
  const quickAccessPortal = QuickAccessPortal({ basePath: "/beeline" });

  const app: AuthenticatedApp = {
    me,
    active: ActivePortal(),
    init: () => {
      maybeInitSomePortal();
    },
    destroy: () => {
      clearAuthenticatedContext();
    },
  };

  return app;

  function maybeInitSomePortal() {
    if (selfIsEmployee || O.isSome(get(app.active))) return;
    const toSelect =
      get(me).profiles.find(isRole("talent")) || get(me).profiles[0];
    if (toSelect) selectProfile(toSelect);
  }

  function ActivePortal(): AuthenticatedApp["active"] {
    const router = selfIsEmployee ? EmployeeRouter() : NotEmployeeRouter();
    const svelteRouter = routerToSvelteRouter(router);
    return derived(
      [svelteRouter.active, quickAccessPortal.route],
      ([active, quickAccessRoute]) => {
        return pipe(
          quickAccessRoute,
          O.map(
            (): ActivePortal => ({
              name: "quickAccess",
              basePath: quickAccessPortal.basePath,
              model: () => Promise.resolve(quickAccessPortal),
            }),
          ),
          O.alt(() => active),
        );
      },
    );
  }

  function TalentPortal(basePath: string) {
    return (profile: Pick<TalentProfile, "id">): ActivePortal => ({
      name: "talent",
      id: profile.id,
      basePath,
      model: async () => {
        const m = await import("./talent-portal/TalentPortal");
        return m.TalentPortal({ talentId: profile.id, basePath });
      },
    });
  }

  function ProductOwnerPortal(): ActivePortal {
    const basePath = "/product-owner";
    return {
      name: "productOwner",
      basePath,
      model: async () => {
        const m = await import(
          "./employee/product-owner-portal/ProductOwnerPortal"
        );
        return m.ProductOwnerPortal({ basePath });
      },
    };
  }

  function TripPlannerPortal(): ActivePortal {
    const basePath = "/trip-planner";
    return {
      name: "tripPlanner",
      basePath,
      model: async () => {
        const m = await import(
          "./employee/trip-planner-portal/TripPlannerPortal"
        );
        return m.TripPlannerPortal({ basePath });
      },
    };
  }

  function BrandManagerPortal(profile: BrandManagerProfile): ActivePortal {
    const basePath = "/brand-manager";
    return {
      name: "brandManager",
      basePath,
      model: async () => {
        const m = await import(
          "./employee/brand-manager-portal/BrandManagerPortal"
        );
        return m.BrandManagerPortal({ basePath, profile });
      },
    };
  }

  function PurchaseManagerPortal(
    profile: PurchaseManagerProfile,
  ): ActivePortal {
    const basePath = "/purchase-manager";
    return {
      name: "purchaseManager",
      basePath,
      model: async () => {
        const m = await import(
          "./employee/purchase-manager-portal/PurchaseManagerPortal"
        );
        return m.PurchaseManagerPortal({
          basePath,
          profile,
        });
      },
    };
  }

  function FinancePortal(profile: FinanceProfile): ActivePortal {
    const basePath = "/finance";
    return {
      name: "finance",
      basePath,
      model: async () => {
        const m = await import("./employee/finance-portal/FinancePortal");
        return m.FinancePortal({ basePath, profile });
      },
    };
  }

  function InventoryAndPaymentCoordinatorPortal(
    profile: InventoryAndPaymentCoordinatorProfile,
  ): ActivePortal {
    const basePath = "/inventory-payment-coordinator";
    return {
      name: "inventoryAndPaymentCoordinator",
      basePath,
      model: async () => {
        const m = await import(
          "./employee/inventory-and-payment-coordinator-portal/InventoryAndPaymentCoordinatorPortal"
        );
        return m.InventoryAndPaymentCoordinatorPortal({
          basePath,
          profile,
        });
      },
    };
  }

  function NotEmployeeRouter() {
    return Router<ActivePortal>({
      history,
      routes: {
        "/talent{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("talent")),
            O.map(TalentPortal("/talent")),
          ),
      },
      isSameRoute: isSamePortal,
    });
  }

  function EmployeeRouter() {
    return Router<ActivePortal>({
      history,
      routes: {
        "/talent/:talentId{/*}?": ({ params }) =>
          pipe(
            params.talentId,
            ids.TalentIdCodec.decode,
            O.fromEither,
            O.map((id) =>
              pipe(
                get(me).profiles,
                A.findFirst(isTalentProfile(id)),
                O.getOrElse(() => ({ id, role: "talent" })),
                TalentPortal(`/talent/${id}`),
              ),
            ),
          ),
        "/trip-planner{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("tripPlanner")),
            O.map(() => TripPlannerPortal()),
          ),
        "/product-owner{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("productOwner")),
            O.map(() => ProductOwnerPortal()),
          ),
        "/brand-manager{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("brandManager")),
            O.map(BrandManagerPortal),
          ),
        "/purchase-manager{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("purchaseManager")),
            O.map(PurchaseManagerPortal),
          ),
        "/finance{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("finance")),
            O.map(FinancePortal),
          ),
        "/inventory-payment-coordinator{/*}?": () =>
          pipe(
            get(me).profiles,
            A.findFirst(isRole("inventoryAndPaymentCoordinator")),
            O.map(InventoryAndPaymentCoordinatorPortal),
          ),
      },
      isSameRoute: isSamePortal,
    });
  }
};
export type { ActivePortal as AuthenticatedAppView };

type LazyLoaded<T> = () => Promise<T>;

type ActivePortalBranches =
  | { name: "admin"; model: never }
  | { name: "quickAccess"; model: LazyLoaded<QuickAccessPortal> }
  | { name: "brandManager"; model: LazyLoaded<BrandManagerPortal> }
  | { name: "purchaseManager"; model: LazyLoaded<PurchaseManagerPortal> }
  | { name: "productOwner"; model: LazyLoaded<ProductOwnerPortal> }
  | { name: "finance"; model: LazyLoaded<FinancePortal> }
  | {
      name: "inventoryAndPaymentCoordinator";
      model: LazyLoaded<InventoryAndPaymentCoordinatorPortal>;
    }
  | { name: "talent"; id: TalentProfile["id"]; model: LazyLoaded<TalentPortal> }
  | { name: "tripPlanner"; model: LazyLoaded<TripPlannerPortal> };
type ActivePortal = ActivePortalBranches & { basePath: string };

const isSamePortal = (a: ActivePortal, b: ActivePortal) => {
  switch (a.name) {
    case "talent":
      return b.name === "talent" && a.id === b.id;
    default:
      return a.name === b.name;
  }
};

const SelectProfile =
  (history: History, me: Readable<Me>, selfIsEmployee: boolean) =>
  (profile: Profile) => {
    const path = getProfilePath(get(me), profile, selfIsEmployee);
    history.push(path);
  };

const getProfilePath = (
  me: Me,
  profile: Profile,
  selfIsEmployee: boolean,
): string => {
  switch (profile.role) {
    case "admin":
      return "/admin";

    case "brandManager":
      return "/brand-manager/manage-talents";

    case "purchaseManager":
      return "/purchase-manager";

    case "productOwner":
      return "/product-owner/manage-talents";

    case "inventoryAndPaymentCoordinator":
      return "/inventory-payment-coordinator";

    case "talent":
      return selfIsEmployee ? `/talent/${profile.id}` : "/talent";

    case "guest": {
      const isSelf = me.profiles.some(
        (p) => p.role === "guest" && p.email === profile.email,
      );
      return isSelf ? "/guest" : `/employee-as-guest/${profile.email}`;
    }

    case "tripPlanner":
      return "/trip-planner";

    case "finance":
      return "/finance";
  }
};
