<script lang="ts">
  import { O } from "@seekdharma/std";
  import { onDestroy } from "svelte";
  import LazyLoad from "../components/LazyLoad.svelte";
  import type { AnonymousPortal } from "./AnonymousPortal";

  export let model: AnonymousPortal;
  $: model.init();
  $: active = model.route;
  onDestroy(model.destroy);
</script>

{#if O.isNone($active)}
  Not found, this should not happen.
{:else}
  {@const { value: route } = $active}
  {#if route.name === "survey"}
    {@const { surveyId } = route}
    <LazyLoad
      component={() => import("./pages/survey/Survey.svelte")}
      props={{ api: model.api, surveyId }}
    />
  {:else if route.name === "sandbox"}
    <LazyLoad
      component={() => import("./pages/SandboxPage.svelte")}
      props={{}}
    />
  {/if}
{/if}
