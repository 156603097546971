import { ids } from "@seekdharma/common-types";
import { O, pipe, string } from "@seekdharma/std";
import type { History } from "history";
import type { Readable } from "svelte/store";
import { getAppContext } from "../AppContext";
import { lookup } from "../lib/fp-ts";
import { Router } from "../lib/router";
import { routerToSvelteRouter } from "../lib/router-svelte";
import { PageViewTracker } from "../lib/Tracker";
import { AnonymousApi } from "./AnonymousApi";

export interface AnonymousPortal {
  readonly route: Readable<O.Option<AnonymousPortalRoute>>;
  readonly api: AnonymousApi;
  init: () => void;
  destroy: () => void;
}

type Deps = {
  api?: AnonymousApi;
};

export const AnonymousPortal = ({
  api = AnonymousApi(),
}: Deps = {}): AnonymousPortal => {
  const { history, tracker } = getAppContext();
  const router = routerToSvelteRouter(AnonymousPortalRouter(history));
  const pageView = PageViewTracker({
    tracker,
    route: router.active,
    portal: "AnonymousPortal",
    path: lookup("name"),
  });
  const portal: AnonymousPortal = {
    api,
    route: router.active,
    init: () => {
      pageView.track();
    },
    destroy: () => {
      pageView.untrack();
    },
  };
  return portal;
};

export type AnonymousPortalRoute = SurveyRoute | SandboxRoute;

type SandboxRoute = { name: "sandbox" };
type SurveyRoute = {
  name: "survey";
  surveyId: ids.SurveyId;
};

const AnonymousPortalRouter = (history: History) =>
  Router<AnonymousPortalRoute>({
    history,
    routes: {
      "/survey/:surveyId": ({ params }) =>
        pipe(
          O.fromNullable(params.surveyId),
          O.map(string.toUpperCase),
          O.map(ids.SurveyIdCodec.decode),
          O.chain(O.fromEither),
          O.map((surveyId) => ({ name: "survey", surveyId })),
        ),
      "/sandbox": () => O.some({ name: "sandbox" }),
    },

    isSameRoute: (a: AnonymousPortalRoute, b: AnonymousPortalRoute) => {
      switch (a.name) {
        case "survey":
          return b.name === "survey" && a.surveyId === b.surveyId;
        default:
          return a.name === b.name;
      }
    },
  });
